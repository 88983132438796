import React, { useEffect, useState } from 'react';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import { Router, useRouter } from 'next/router';
import NProgress from 'nprogress';
import '@/styles/mixins.less';
import '@/styles/globals.less';
import '@/styles/fonts.css';
import 'nprogress/nprogress.css';
import { App as AntdApp } from 'antd';
import ConfigProvider, { useConfigProvider } from '@/context/ConfigProvider';
import ShoppingCartProvider from '@/context/UserProvider';
import withTheme from '@/theme';
import '../lib/hooks/common/firebaseConfig/init';
import { appWithTranslation } from 'next-i18next';
import { Locale } from 'antd/lib/locale';
import { CurrentLanguage } from '@/shared/language';
// import nextI18nConfig from '../../next-i18next.config'

Router.events.on('routeChangeStart', NProgress.start);
Router.events.on('routeChangeError', NProgress.done);
Router.events.on('routeChangeComplete', NProgress.done);

if (process.env.NEXT_APP_TENANTID === '4') {
  require('../styles/hp-theme.less');
} else if (process.env.NEXT_APP_TENANTID === '3' || process.env.NEXT_APP_TENANTID === '5') {
  require('../styles/mg-theme.less');
} else if (process.env.NEXT_APP_TENANTID === '2') {
  require('../styles/qt-theme.less');
} else {
  require('../styles/df-theme.less');
}

export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<P = {}> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  return withTheme(
    <AntdApp>
      <ConfigProvider>
        <ShoppingCartProvider>{getLayout(<Component {...pageProps} />)}</ShoppingCartProvider>
      </ConfigProvider>
    </AntdApp>,
    loc,
  );
}

export default appWithTranslation(App);
